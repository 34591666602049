import { render, staticRenderFns } from "./ConsultaTab.vue?vue&type=template&id=8435e304&scoped=true&"
import script from "./ConsultaTab.vue?vue&type=script&lang=js&"
export * from "./ConsultaTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8435e304",
  null
  
)

export default component.exports