<template>
  <div>
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-tabs centered slider-color="primary" :value="0">
            <v-tab>Contratos</v-tab>
            <v-tab-item>
              <ConsultaContratos />
            </v-tab-item>
            <v-tab>Apólices</v-tab>
            <v-tab-item>
              <ConsultaApolices />
            </v-tab-item>
            <v-tab>Comissões</v-tab>
            <v-tab-item>
              <ConsultaComissoes />
            </v-tab-item>
            <v-tab v-if="currentUser.tipo !== 'V'">Ranking de vendas</v-tab>
            <v-tab-item>
              <ConsultaRanking />
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
const ConsultaContratos = () => import('./ConsultaContratos')
const ConsultaApolices = () => import('./ConsultaApolices')
const ConsultaComissoes = () => import('./ConsultaComissoes')
const ConsultaRanking = () => import('./ConsultaRanking')
import { mapGetters } from 'vuex'

export default {
  name: 'ConsultasTabs',
  components: {
    ConsultaContratos,
    ConsultaApolices,
    ConsultaComissoes,
    ConsultaRanking
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}

</script>

<style scoped>
</style>